<template>
  <div v-if="slider != null">
    <b-carousel
      id="carousel-1"
      v-model="slide"
      :interval="5000"
      indicators
      img-width="1920"
      img-height="300"
      style="text-shadow: 1px 1px 2px #333"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
      class="hero-banner-carousel category-sliderMain"
    >
      <b-link
        :href="bSlide.link"
        class="btn btn-dark ml-30 ml-md-0"
        v-for="(bSlide, index) in slider.slides"
        :key="index"
        :target="getTarget(bSlide.link)"
        :rel="getRel(bSlide.link)"
      >
        <b-carousel-slide
          :style="{ backgroundImage: `url(${bSlide.optImage})` }"
          class="category-slider"
        >
          <div class="container">
            <!-- <span class="d-block text-uppercase text-light font-weight-normal">{{
            bSlide.title
          }}</span>
          <v-runtime-template
            v-if="bSlide.description != null"
            :template="bSlide.description"
          ></v-runtime-template> -->
          </div>
        </b-carousel-slide>
      </b-link>
    </b-carousel>
  </div>
</template>

<script>
// import VRuntimeTemplate from "v-runtime-template";
import { isMobile } from "mobile-device-detect";
import { isTablet } from "mobile-device-detect";
import { isBrowser } from "mobile-device-detect";
import { mapGetters } from "vuex";
export default {
  name: "CatBanner",
  // components: { VRuntimeTemplate },
  data: () => ({
    slide: 0,
    sliding: false,
  }),
  computed: {
    ...mapGetters({ slider1: "category/getSlider" }),
    slider() {
      //return this.$store.getters["category/getSlider"];

      let slider = this.slider1;
      // let slider =
      //   this.$store.getters["sliders/getSliderByIdentifier"]("category_slider");

      if (slider != null) {
        if (slider.slides != null) {
          slider.slides.forEach((element) => {
            if (this.mobileCheck == "browser") {
              element.optImage = element.media.url;
            } else if (this.mobileCheck == "tablet") {
              element.optImage = element.media.url;
            } else {
              element.optImage = element.media.url;
            }
          });
        }
      }

      return slider;
    },
    mobileCheck() {
      if (isBrowser) {
        return "browser";
      }
      if (isMobile && isTablet) {
        return "tablet";
      }
      return "mobile";
    },
  },
  methods: {
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
    getTarget(link) {
      if (link && link.substring(0, 4) == "http") {
        return "_blank";
      } else {
        return "_self";
      }
    },
    getRel(link) {
      if (link && link.substring(0, 4) == "http") {
        return "noopener noreferrer";
      } else {
        return "";
      }
    },
  },
};
</script>

<style lang="scss">
</style>
