var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_vm._l((_vm.productsList),function(product,idx){return [(
          idx == 2 && _vm.gridSlider && _vm.gridSlider.slides && _vm.viewType == 'grid'
        )?_c('div',{key:idx,staticClass:"col-sm-6 col-md-6 col-lg-4 col-6"},[_c('Advertisement',{attrs:{"slider":_vm.gridSlider}})],1):_vm._e(),(
          idx == 6 && _vm.listSlider && _vm.listSlider.slides && _vm.viewType == 'list'
        )?_c('div',{key:idx,staticClass:"col-sm-6 col-md-6 col-lg-4 col-6"},[_c('Advertisement',{attrs:{"slider":_vm.listSlider}})],1):_vm._e(),_c('div',{key:product.sku,staticClass:"col-sm-6 col-md-6 col-lg-4 col-6"},[_c('ProductCard',{attrs:{"product":product,"viewType":_vm.viewType}})],1),(
          (_vm.gridSlider && _vm.gridSlider.slides) ||
          (_vm.listSlider && _vm.listSlider.slides)
            ? (idx + 2) % 6 == 0 && idx != _vm.productsList.length
            : (idx + 1) % 6 == 0 && idx + 1 != _vm.productsList.length
        )?_c('div',{key:("info-" + (product.sku)),staticClass:"col-12"},[_c('InformativeLineProductBlock',{attrs:{"isSlider":true}})],1):_vm._e()]})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }