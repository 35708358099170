<template>
  <div>
    <b-modal
      id="modal-dynamic_form"
      okVariant="success"
      okTitle="Ok"
      @ok="clickDynFormOk"
    >
      <div v-html="msg"></div>
    </b-modal>

    <h2>{{ form.title }}</h2>
    <div v-for="(set, idx) in form.fieldsets" :key="`set` + idx">
      <h3>{{ set.title }}</h3>
      <div v-for="(field, index) of set.fields" :key="`fields` + index">
        <div v-if="field.type == 'input'">
          <b-form-group
            class="account-inputs"
            :id="`set` + idx + `field` + index + `group`"
            :label="field.label"
            :label-for="`set` + idx + `field` + index"
          >
            <b-form-input
              :id="`set` + idx + `field` + index"
              :type="field.inputType"
              :aria-describedby="`set` + idx + `field` + index + `feedback`"
              v-model="formValue[idx][index]"
              :state="formState[idx][index]"
            ></b-form-input>
            <b-form-invalid-feedback
              :id="`set` + idx + `field` + index + `feedback`"
              >{{ formError[idx][index] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div v-if="field.type == 'select'">
          <b-form-group
            class="account-inputs"
            :id="`set` + idx + `field` + index + `group`"
            :label="field.label"
            :label-for="`set` + idx + `field` + index"
          >
            <b-form-select
              :id="`set` + idx + `field` + index"
              v-model="formValue[idx][index]"
              :aria-describedby="`set` + idx + `field` + index + `feedback`"
              class="select"
              :state="formState[idx][index]"
            >
              <b-form-select-option
                v-for="opt in field.options"
                :key="opt"
                :value="opt"
                >{{ opt }}</b-form-select-option
              >
            </b-form-select>
            <b-form-invalid-feedback
              :id="`set` + idx + `field` + index + `feedback`"
              >{{ formError[idx][index] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div v-if="field.type == 'textarea'">
          <b-form-group
            class="account-inputs"
            :id="`set` + idx + `field` + index + `group`"
            :label="field.label"
            :label-for="`set` + idx + `field` + index"
          >
            <b-form-textarea
              :id="`set` + idx + `field` + index"
              :aria-describedby="`set` + idx + `field` + index + `feedback`"
              v-model="formValue[idx][index]"
              :state="formState[idx][index]"
              >{{ formValue[idx][index] }}</b-form-textarea
            >
            <b-form-invalid-feedback
              :id="`set` + idx + `field` + index + `feedback`"
              >{{ formError[idx][index] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>
    </div>
    <b-button @click="sendForm()" type="button" variant="info">{{
      $t("send")
    }}</b-button>
  </div>
</template>

<script>
import { Logger } from "@storefront/core/lib/logger";
import { mapGetters } from "vuex";
import { sendForm } from "@storefront/core/data-resolver/forms";
export default {
  name: "DynamicForms",
  props: {
    category_id: {
      type: Number,
      required: false,
    },
    product_sku: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      required: true,
    },
  },
  methods: {
    clickDynFormOk() {
      this.$bvModal.hide("modal-dynamic_form");
    },
    async sendForm() {
      this.form.fieldsets.forEach((set, idx) => {
        set.fields.forEach((field, index) => {
          let state = true;
          let error = "";
          field.validation.forEach((val) => {
            Logger.debug(field.name, "sendForm", val.type)();
            if (val.type == "required") {
              if (this.formValue[idx][index] == "") {
                state = false;
                error = this.$t("dyform_required");
              }
            } else if (val.type == "alpha_numeric") {
              if (this.alpha_numeric.test(this.formValue[idx][index]) != true) {
                state = false;
                error = this.$t("dyform_alpha_numeric");
              }
            } else if (val.type == "alpha_numeric_with_spaces") {
              if (
                this.alpha_numeric_with_spaces.test(
                  this.formValue[idx][index]
                ) != true
              ) {
                state = false;
                error = this.$t("dyform_alpha_numeric_with_spaces");
              }
            } else if (val.type == "letters_only") {
              if (this.letters_only.test(this.formValue[idx][index]) != true) {
                state = false;
                error = this.$t("dyform_letters_only");
              }
            } else if (val.type == "disallow_html_tags") {
              if (
                this.disallow_html_tags.test(this.formValue[idx][index]) == true
              ) {
                state = false;
                error = this.$t("dyform_disallow_html_tags");
              }
            } else if (val.type == "email") {
              if (this.emailaddress.test(this.formValue[idx][index]) != true) {
                state = false;
                error = this.$t("dyform_emailaddress");
              }
            } else if (val.type == "url") {
              if (this.url.test(this.formValue[idx][index]) != true) {
                state = false;
                error = this.$t("dyform_url");
              }
            } else if (val.type == "min_text_length") {
              if (
                this.formValue[idx][index].length < parseInt(val.additional)
              ) {
                state = false;
                error = this.$t("dyform_min_text_length", {
                  aantal: parseInt(val.additional),
                });
              }
            } else if (val.type == "max_text_length") {
              if (
                this.formValue[idx][index].length > parseInt(val.additional)
              ) {
                state = false;
                error = this.$t("max_text_length", {
                  aantal: parseInt(val.additional),
                });
              }
            } else if (val.type == "numeric") {
              if (this.numeric.test(this.formValue[idx][index]) != true) {
                state = false;
                error = this.$t("dyform_numeric");
              }
            } else if (val.type == "numeric_with_decimals") {
              if (
                this.numeric_with_decimals.test(this.formValue[idx][index]) !=
                true
              ) {
                state = false;
                error = this.$t("dyform_numeric_with_decimals");
              }
            } else if (val.type == "greater_then_or_equal_to") {
              //
            } else if (val.type == "less_then_or_equal_to") {
              //
            }
          });
          Logger.debug("this.formState", "dynamic Forms", this.formState)();
          Logger.debug("this.formError", "dynamic Forms", this.formError)();
          this.$set(this.formState[idx], index, state);
          //this.formState[idx][index] = state;
          //this.formError[idx][index] = error;
          this.$set(this.formError[idx], index, error);
        });
      });
      let save = true;
      this.form.fieldsets.forEach((set, idx) => {
        set.fields.forEach((field, index) => {
          if (this.formState[idx][index] != true) {
            save = false;
          }
        });
      });
      if (save == true) {
        //send form
        let input =
          "type: " + this.type + " " + this.remote_entity_id + " field: [";
        this.form.fieldsets.forEach((set, idx) => {
          set.fields.forEach((field, index) => {
            input =
              input +
              '{ name: "' +
              field.name +
              '", value: "' +
              this.formValue[idx][index] +
              '" }';
          });
        });
        input = input + "]";
        const retval = await sendForm(input);
        Logger.debug("retval", "sendForm", retval)();
        if (retval.has_errors == true) {
          //
        } else {
          this.msg = retval.thank_you_message;
          this.$bvModal.show("modal-dynamic_form");
          this.form.fieldsets.forEach((set, idx) => {
            set.fields.forEach((field, index) => {
              this.$set(this.formValue[idx], index, "");
              this.$set(this.formState[idx], index, null);
              this.$set(this.formError[idx], index, "");
            });
          });
        }
      }
    },
  },
  created() {
    Logger.debug("form", "DynamicForms", this.form)();
    this.form.fieldsets.forEach((set, idx) => {
      this.$set(this.formValue, idx, []);
      this.$set(this.formState, idx, []);
      this.$set(this.formError, idx, []);
      set.fields.forEach((field, index) => {
        this.$set(this.formValue[idx], index, "");
        this.$set(this.formState[idx], index, null);
        this.$set(this.formError[idx], index, "");
      });
    });
  },
  data() {
    return {
      formValue: [],
      formState: [],
      formError: [],
      alpha_numeric: /^[a-zA-Z0-9]+$/,
      alpha_numeric_with_spaces: /^[a-zA-Z0-9 ]+$/,
      letters_only: /^[a-zA-Z]+$/,
      url: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
      numeric: /^\d+$/,
      numeric_with_decimals: /^\d+(?:\.|,)\d+$/,
      disallow_html_tags: /(<([^>]+)>)/gi,
      emailaddress:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      msg: "",
    };
  },
  computed: {
    ...mapGetters({
      product: "product/getCurrentProduct",
      category: "category/getCurrentCategory",
    }),
    remote_entity_id() {
      if (this.type == "product") {
        return 'remote_entity_id: "' + this.product_sku + '" ';
      } else if (this.type == "category") {
        return 'remote_entity_id: "' + this.category_id + '" ';
      }
      return "";
    },
    form() {
      if (this.type == "product") {
        return this.$store.getters["forms/getProductForm"];
      } else if (this.type == "category") {
        return this.$store.getters["forms/getCategoryForm"];
      }
      return this.$store.getters["forms/getContactForm"];
    },
  },
  watch: {
    product() {
      Logger.debug("watch product", "DynamicForms", "changed")();

      if (this.type == "product") {
        const fValue = [];
        const fState = [];
        const fError = [];
        Logger.debug("watch product", "DynamicForms", "changed2")();
        this.form.fieldsets.forEach((set, idx) => {
          fValue[idx] = [];
          fState[idx] = [];
          fError[idx] = [];
          set.fields.forEach((field, index) => {
            fValue[idx][index] = "";
            fState[idx][index] = null;
            fError[idx][index] = "";
          });
        });
        this.formValue = fValue;
        this.formState = fState;
        this.formError = fError;
      }
    },
    category() {
      Logger.debug("watch category", "DynamicForms", "changed")();

      if (this.type == "category") {
        const fValue = [];
        const fState = [];
        const fError = [];
        Logger.debug("watch category", "DynamicForms", "changed2")();
        this.form.fieldsets.forEach((set, idx) => {
          fValue[idx] = [];
          fState[idx] = [];
          fError[idx] = [];
          set.fields.forEach((field, index) => {
            fValue[idx][index] = "";
            fState[idx][index] = null;
            fError[idx][index] = "";
          });
        });
        this.formValue = fValue;
        this.formState = fState;
        this.formError = fError;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
