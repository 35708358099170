<template>
  <div>
    <div class="row">
      <template v-for="(product, idx) of productsList">
        <div
          class="col-sm-6 col-md-6 col-lg-4 col-6"
          v-if="
            idx == 2 && gridSlider && gridSlider.slides && viewType == 'grid'
          "
          :key="idx"
        >
          <Advertisement :slider="gridSlider" />
        </div>
        <div
          class="col-sm-6 col-md-6 col-lg-4 col-6"
          v-if="
            idx == 6 && listSlider && listSlider.slides && viewType == 'list'
          "
          :key="idx"
        >
          <Advertisement :slider="listSlider" />
        </div>

        <div class="col-sm-6 col-md-6 col-lg-4 col-6" :key="product.sku">
          <ProductCard :product="product" :viewType="viewType" />
        </div>

        <div
          class="col-12"
          v-if="
            (gridSlider && gridSlider.slides) ||
            (listSlider && listSlider.slides)
              ? (idx + 2) % 6 == 0 && idx != productsList.length
              : (idx + 1) % 6 == 0 && idx + 1 != productsList.length
          "
          :key="`info-${product.sku}`"
        >
          <InformativeLineProductBlock :isSlider="true" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import ProductCard from "@/base/core/components/products/category-product/ProductCard";
import InformativeLineProductBlock from "@/base/core/components/common/InformativeLineProductBlock";
import Advertisement from "@/base/core/components/products/category-product/InlineCategoryAdvertisement";
export default {
  name: "ProductsSection",
  components: {
    ProductCard,
    InformativeLineProductBlock,
    Advertisement,
  },
  props: {
    productsList: {
      type: Array,
      required: true,
    },
    viewType: {
      type: String,
      required: true,
    },
  },
  methods: {
    ...mapActions(["setProductStatus"]),
  },
  computed: {
    useWidthClass() {
      const cat = this.$store.getters["category/getCurrentCategory"];
      if (cat != null) {
        if (cat.brand != null) {
          return "col-md-3";
        }
      }
      return "col-md-4";
    },
    gridSlider: {
      get() {
        var sliders = this.$store.getters["categorySlider/getCategorySliders"];
        var catSlider = {};
        if (sliders) {
          sliders.forEach((slider) => {
            if (slider.identifier.includes("category_advertisement_grid")) {
              catSlider = slider;
            }
          });
        }
        return catSlider;
      },
      set() {
        //Nothing to do
      },
    },
    listSlider: {
      get() {
        var sliders = this.$store.getters["categorySlider/getCategorySliders"];
        var catSlider = {};
        if (sliders) {
          sliders.forEach((slider) => {
            if (slider.identifier.includes("category_advertisement_list")) {
              catSlider = slider;
            }
          });
        }
        return catSlider;
      },
      set() {
        //Nothing to do
      },
    },
  },
};
</script>
