<template>
  <div class="informative-sec">
    <div class="container">
      <!-- <div v-if="!mobileCheck">
        <cmsBlockSimple
          v-if="typeof headerInforativeCms == 'object'"
          :identifier="headerInforativeCms.identifier"
          class="d-flex justify-content-between align-items-center"
        />
      </div> -->
      <!-- <div v-if="isSlider || mobileCheck" class="informative-slider"> -->
      <div
        class="informative-slider"
        v-if="typeof productuspTextSlider != 'undefined'"
      >
        <ClientOnly>
          <VueSlickCarousel
            :arrows="false"
            :dots="false"
            :slidesToShow="3"
            :autoplay="true"
            :responsive="[
              {
                breakpoint: 1199,
                settings: {
                  slidesToShow: 2,
                },
              },
              {
                breakpoint: 767,
                settings: {
                  slidesToShow: 2,
                },
              },
              {
                breakpoint: 540,
                settings: {
                  slidesToShow: 1,
                },
              },
            ]"
          >
            <div
              class="cms-block-content"
              v-for="(slide, index) in productuspTextSlider.slides"
              :key="index"
            >
              <b-link
                :to="slide.link"
                class=""
                v-if="slide.link && slide.link.substring(0, 4) !== 'http'"
              >
                <div v-html="slide.description"></div>
              </b-link>
              <b-link
                :href="slide.link"
                target="_blank"
                class=""
                rel="noopener noreferrer"
                v-if="slide.link && slide.link.substring(0, 4) === 'http'"
              >
                <div v-html="slide.description"></div>
              </b-link>
              <b-link to="" class="" v-if="!slide.link">
                <div v-html="slide.description"></div>
              </b-link>
            </div>
          </VueSlickCarousel>
        </ClientOnly>
      </div>
      <!-- <div v-else>
        <cmsBlockSimple
          v-if="typeof headerInforativeCms == 'object'"
          :identifier="headerInforativeCms.identifier"
          class="d-flex justify-content-between align-items-center"
        />
      </div> -->
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import ClientOnly from "vue-client-only";
// import cmsBlockSimple from "@/base/core/components/core/BlockSimple";

import { isMobile } from "mobile-device-detect";
import { isTablet } from "mobile-device-detect";
export default {
  name: "InformativeLineProductBlock",
  components: {
    VueSlickCarousel,
    // cmsBlockSimple,
    ClientOnly,
  },
  props: {
    isSlider: Boolean,
  },
  computed: {
    mobileCheck() {
      if (isMobile || isTablet) {
        return true;
      }
      return false;
    },
    headerInforativeCms() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "header_informative_text"
      );
    },
    productuspTextSlider() {
      return this.$store.getters["sliders/getSliderByIdentifier"](
        "productusp_text_slider"
      );
    },
    informativeSlider() {
      return this.$store.getters["sliders/getSliderByIdentifier"](
        "informative_text_slider"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
