<template>
  <div class="product-box">
    <!-- AddToCompare Modal -->
    <b-modal
      v-if="comparePopupProduct"
      id="addToCompare"
      title
      size="lg"
      content-class="product-popup product-compare-popup"
      dialog-class="cart-modal"
      centered
      v-model="addToComparePopup"
      :hide-footer="true"
    >
      <b-row class="add-to-cart-popup">
        <b-col col lg="9">
          <div class="title mb-3">
            <h4>{{ $t("compare_popup_title") }}</h4>
          </div>
          <div class="addToCart-wrap mb-4">
            <div class="cart-product-details">
              <div class="image">
                <img :src="comparePopupProduct.thumbnail.url" />
                <div class="cart-product-name">
                  {{ comparePopupProduct.name }}
                </div>
              </div>
              <div class="cart-product-price">
                {{
                  formatPrice(
                    comparePopupProduct.price_range.maximum_price.final_price
                      .value
                  )
                }}
              </div>
            </div>
          </div>
          <div class="cart-buttons-wrap d-flex justify-content-between">
            <button class="btn btn-primary" @click="clickOk">
              {{ $t("compare") }}
            </button>
            <button class="btn btn-gray" @click="clickCancel">
              {{ $t("continue_shoping") }}
            </button>
          </div>
        </b-col>
        <b-col lg="3">
          <div class="person-img">
            <img
              src="@/base/assets/add_to_cart_popup.png"
              alt=""
            />
          </div>
        </b-col>
      </b-row>
    </b-modal>
    <!-- product image -->
    <div class="img-wrap">
      <b-link :to="`/${product.url_key}`" class="h-full">
        <!-- v-if="product.thumbnail.medium != null" -->
        <div
          class="product-img"
          :style="`background-image: url('${product.thumbnail.medium}');`"
        >
          <div class="product-tag">
            <div
              class="tag discount-txt-wrap"
              v-if="product.cashback_promotion"
            >
              <span class="discount-txt">
                <span
                  v-if="
                    product.cashback_promotion.amount &&
                    product.cashback_promotion.amount.value &&
                    product.cashback_promotion.amount.value !== null
                  "
                >
                  &euro;{{ product.cashback_promotion.amount.value + ",-=" }}
                </span>
                <span v-if="product.cashback_promotion.name">
                  {{ product.cashback_promotion.name }}
                </span>
              </span>
            </div>
            <div
              class="product-tag-wrap"
              v-if="product.labels && product.labels.length"
            >
              <div
                class="product-tag-single"
                v-for="(label, idx) in product.labels"
                :key="idx"
              >
                <div v-if="label.category.type === 'image'" class="img-label">
                  <div
                    class="tag-label"
                    :class="label.category.position"
                    :style="
                      `width:${label.category.size}px;
                      height:${label.category.size}px;
                      background-image: url('` +
                      label.category.image_url +
                      `');
                      ${label.category.css};`
                    "
                  />
                </div>

                <div v-if="label.category.type === 'text'" class="text-label">
                  <div
                    class="tag-label"
                    :class="label.category.position"
                    :style="label.category.css"
                  >
                    {{ label.category.text }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-link>
    </div>

    <div class="product-more">
      <div class="product-other-info">
        <b-link :to="`/${product.url_key}`" class="product-card">
          <div class="product-name">{{ product.name }}</div>
        </b-link>
        <div class="rating-wrap" v-if="!mobileCheck">
          <div
            data-bv-show="inline_rating"
            :data-bv-product-id="product.sku"
            :data-bv-redirect-url="product.url_key"
          ></div>
        </div>
        <div class="rating-wrap" v-else>
          <div
            data-bv-show="inline_rating"
            :data-bv-product-id="product.sku"
            :data-bv-redirect-url="product.url_key"
          ></div>
        </div>

        <!-- <div class="extra-specification desktop-specs">
          <template v-for="(attr, idx) of highlightedAttr">
            <span :key="idx">{{ attr.label }}:</span>
            {{ attr.value.split(",")[0]
            }}{{ idx + 1 != highlightedAttr.length ? " | " : "" }}
          </template>
        </div> -->

        <div class="extra-specification desktop-specs">
          <template v-for="(attr, idx) of product.highlight_attributes">
            <span :key="idx">{{ attr.label }}:</span>
            {{ attr.values.map((val) => val.label).join(", ") }}
            {{ idx + 1 != product.highlight_attributes.length ? " | " : "" }}
          </template>
          <!-- <div class="extrainfo-wrap" v-for="attr in product.highlight_attributes" :key="attr.label">
            <span>{{attr.label}}: </span>
            <span>{{ attr.values.map((val)=>val.label).join(", ") }}</span>
          </div> -->
        </div>

        <div class="product-extra list-view" v-if="!mobileCheck">
          <div class="add-wish">
            <b-link
              @click="toggleWishItem"
              :class="{ 'in-wishlist': isInWishList }"
            >
              <i class="fas fa-heart"></i>
              <label>{{ $t("wishlist") }}</label>
            </b-link>
          </div>
          <div class="add-compare">
            <b-form-checkbox
              id="compare"
              label-for="compare"
              :checked="isInCompare(product.sku)"
              @change="changeCompareStatus($event, product.sku)"
              >{{ $t("compare") }}</b-form-checkbox
            >
          </div>
        </div>

        <div class="price-bar grid-view">
          <DeliveryTime :data="product.DeliveryTime" />

          <PickupStores
            :product="product && product"
            :key="'grid'"
            :cardType="'grid'"
          />

          <div class="wrap-block">
            <div class="price-wrap">
              <div
                v-if="
                  product.price_range.minimum_price.discount.percent_off >= 5
                "
                class="d-flex align-items-end price-selection"
              >
                <span class="normal-price">
                  <span>{{ $t("advice_price") }}:</span>
                  <span class="original-price">
                    <span class="price-stroke"></span>
                    {{
                      formatPrice(
                        product.price_range.minimum_price.regular_price.value
                      )
                    }}
                  </span>
                </span>
              </div>
              <span
                v-if="
                  product.price_range.minimum_price.final_price.value ==
                  product.price_range.maximum_price.final_price.value
                "
                class="product-price"
              >
                {{
                  formatPrice(
                    product.price_range.minimum_price.final_price.value
                  )
                }}&nbsp;
                <span v-if="isProductInTrade">{{ $t("after_exchange") }}</span>
              </span>
            </div>
            <div class="add-cart" @click="addToCart">
              <i class="fas fa-shopping-cart"></i>
            </div>
          </div>
        </div>

        <span
          v-if="
            product.price_range.minimum_price.final_price.value !=
            product.price_range.maximum_price.final_price.value
          "
          class="d-block product-card--price"
        >
          {{
            formatPrice(product.price_range.minimum_price.final_price.value)
          }}-
          {{ formatPrice(product.price_range.maximum_price.final_price.value) }}
        </span>
      </div>

      <div class="product-extra grid-view">
        <div class="add-wish">
          <b-link
            @click="toggleWishItem"
            :class="{ 'in-wishlist': isInWishList }"
          >
            <i class="fas fa-heart"></i>
            <label>{{ $t("wishlist") }}</label>
          </b-link>
        </div>
        <div class="add-compare">
          <b-form-checkbox
            id="compare"
            label-for="compare"
            :checked="isInCompare(product.sku)"
            @change="changeCompareStatus($event, product.sku)"
            >{{ $t("compare") }}</b-form-checkbox
          >
        </div>
      </div>

      <div class="price-bar list-view">
        <div>
          <div class="product-price-block">
            <div
              v-if="product.price_range.minimum_price.discount.percent_off >= 5"
              class="d-flex align-items-end price-selection"
            >
              <span class="normal-price">
                <span>{{ $t("advice_price") }}:</span>
                <span class="original-price">
                  <span class="price-stroke"></span>
                  {{
                    formatPrice(
                      product.price_range.minimum_price.regular_price.value
                    )
                  }}
                </span>
              </span>
            </div>
            <!-- <div class="exchange-price">{{ $t('price_after_exchange') }}</div> -->
            <span
              v-if="
                product.price_range.minimum_price.final_price.value ==
                product.price_range.maximum_price.final_price.value
              "
              class="product-price"
            >
              <div class="exchange-price" v-if="isProductInTrade">
                {{ $t("price_after_exchange") }}
              </div>
              {{
                formatPrice(product.price_range.minimum_price.final_price.value)
              }}
            </span>
          </div>

          <DeliveryTime :data="product.DeliveryTime" />

          <PickupStores :product="product && product" cardType="list" />
        </div>

        <div class="add-cart" @click="addToCart">
          <b-link class="btn btn-primary w-full">
            {{ $t("order_now") }}
            <i class="fas fa-shopping-cart"></i>
          </b-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import loaderImg from "@/base/coreassets/loader.gif";
import loaderErrorImg from "@/base/coreassets/loadererror.gif";
import { Logger } from "@storefront/core/lib/logger";
import { isTablet, isMobile } from "mobile-device-detect";
import config from "@config";

import PickupStores from "@/base/core/components/products/product-detail/PickupStores.vue";
import DeliveryTime from "@/base/core/components/products/product-detail/DeliveryTime.vue";

export default {
  name: "ProductCard",
  props: {
    product: {
      type: Object,
      require: true,
    },
    viewType: String,
  },
  data() {
    return {
      loaderImg: loaderImg,
      loaderErrorImg: loaderErrorImg,
      addToComparePopup: false,
      comparePopupProduct: null,
    };
  },
  mounted() {},
  components: {
    PickupStores,
    DeliveryTime,
    // Rating,
    //VueLazyBackgroundImage,
  },
  computed: {
    mobileCheck() {
      if (isMobile || isTablet) {
        return true;
      } else {
        return false;
      }
    },
    isLoggedIn() {
      return this.$store.getters["user/getIsLoggedIn"];
    },
    isProductInTrade() {
      const catId = config.tradeInPromotion;
      if (catId) {
        return this.product.categories.some((category) => category.id == catId);
      }
      return false;
    },
    isInWishList() {
      return this.$store.getters["user/isProductInWishlist"](this.product.sku);
    },
    compareList() {
      return this.$store.getters["product/getProductCompare"];
    },

    // highlightedAttr() {
    //   const infoAttr = this.product.information_attributes;
    //   const highAttr = this.product.highlighted_attributes;
    //   const highAttrArr = [];

    //   if (highAttr) {
    //     const newArr = highAttr
    //       .split(",")
    //       .map((item) => item.replace("[", "").replace("]", ""));

    //     newArr.forEach((e) => {
    //       const existVal = infoAttr.find((e1) => e === e1.attribute_code);
    //       if (existVal) highAttrArr.push(existVal);
    //     });
    //   }

    //   return highAttrArr;
    // },
    finalPrice() {
      if (this.product.__typename == "ConfigurableProduct") {
        if (this.$store.getters["product/getCurrentChildSku"] != null) {
          const found = this.productConfig.variants.find((v) => {
            if (
              v.product.sku == this.$store.getters["product/getCurrentChildSku"]
            ) {
              return true;
            }
          });
          if (found != null) {
            return found.product.price_range.minimum_price.final_price.value.toFixed(
              2
            );
          }
        }
        if (
          this.product.price_range.minimum_price.final_price.value !=
          this.product.price_range.maximum_price.final_price.value
        ) {
          return (
            this.product.price_range.minimum_price.final_price.value.toFixed(
              2
            ) +
            " - " +
            this.product.price_range.maximum_price.final_price.value
              .toFixed(2)
              .replace(".", ",")
          );
        }
        return this.product.price_range.minimum_price.final_price.value.toFixed(
          2
        );
      }
      if (
        this.product.price_range.minimum_price.final_price.value !=
        this.product.price_range.maximum_price.final_price.value
      ) {
        return (
          this.product.price_range.minimum_price.final_price.value
            .toFixed(2)
            .replace(".", ",") +
          " - " +
          this.product.price_range.maximum_price.final_price.value
            .toFixed(2)
            .replace(".", ",")
        );
      }
      return this.product.price_range.minimum_price.final_price.value.toFixed(
        2
      );
    },
    regularPrice() {
      if (this.product.__typename == "ConfigurableProduct") {
        if (this.$store.getters["product/getCurrentChildSku"] != null) {
          const found = this.productConfig.variants.find((v) => {
            if (
              v.product.sku == this.$store.getters["product/getCurrentChildSku"]
            ) {
              return true;
            }
          });
          if (found != null) {
            return found.product.price_range.minimum_price.regular_price.value.toFixed(
              2
            );
          }
        }
        if (
          this.product.price_range.minimum_price.regular_price.value !=
          this.product.price_range.maximum_price.regular_price.value
        ) {
          return (
            this.product.price_range.minimum_price.regular_price.value.toFixed(
              2
            ) +
            " - " +
            this.product.price_range.maximum_price.regular_price.value
              .toFixed(2)
              .replace(".", ",")
          );
        }
        return this.product.price_range.minimum_price.regular_price.value.toFixed(
          2
        );
      }
      if (
        this.product.price_range.minimum_price.regular_price.value !=
        this.product.price_range.maximum_price.regular_price.value
      ) {
        return (
          this.product.price_range.minimum_price.regular_price.value
            .toFixed(2)
            .replace(".", ",") +
          " - " +
          this.product.price_range.maximum_price.regular_price.value
            .toFixed(2)
            .replace(".", ",")
        );
      }
      return this.product.price_range.minimum_price.regular_price.value.toFixed(
        2
      );
    },
  },
  methods: {
    getGTMItems() {
      let items = {
        item_name: this.product.name,
        item_id: this.product.sku,
        quantity: this.quantity,
        price: this.finalPrice,
      };
      if (this.finalPrice != this.regularPrice) {
        items["discount"] = this.regularPrice - this.finalPrice;
      }
      return items;
    },
    clickOk() {
      this.addToComparePopup = false;
      this.comparePopupProduct = null;
      this.$router.push("/compare");
    },
    clickCancel() {
      this.addToComparePopup = false;
      this.comparePopupProduct = null;
    },
    isInCompare(sku) {
      if (this.compareList) {
        return !!this.compareList.find((product) => product.sku === sku);
      }
      return false;
    },
    changeCompareStatus(event, sku) {
      if (event && !this.isInCompare(sku)) {
        this.addToCompare(sku);
      } else {
        this.removeFromCompare(sku);
      }
    },
    toggleWishItem() {
      if (this.$store.getters["user/getIsLoggedIn"] == true) {
        if (
          this.$store.getters["user/isProductInWishlist"](this.product.sku) ==
          true
        ) {
          this.$store.dispatch("user/setProductWishlistStatus", {
            sku: this.product.sku,
            parentSku: null,
          });
        } else {
          if (this.product.__typename == "SimpleProduct") {
            this.$store.dispatch("user/setProductWishlistStatus", {
              sku: this.product.sku,
              parentSku: null,
            });
            if (this.$gtm.enabled()) {
              if (typeof window.fbq === "function") {
                window.fbq("track", "AddToWishlist");
              }
            }
          } else {
            const msg = {
              type: "danger",
              title: this.$t("wishlist error"),
              text: this.$t("wishlist_select_options"),
            };
            this.$store.dispatch("messages/sendMessage", { message: msg });
            this.$router.push("/" + this.product.url_key);
          }
        }
      } else {
        const msg = {
          type: "danger",
          title: this.$t("wishlist error"),
          text: this.$t("wishlist_logged_in"),
        };
        this.$store.dispatch("messages/sendMessage", { message: msg });
        this.$router.push({ name: "login" });
      }
    },
    async addToCart() {
      switch (this.product.__typename) {
        case "SimpleProduct": {
          const item =
            '{data: {sku: "' +
            this.product.sku +
            '", quantity:' +
            1 +
            // this.quantity +
            "}}  ";

          let retval = await this.$store.dispatch("cart/addToCart", {
            type: this.product.__typename,
            item: item,
          });

          Logger.debug("retval", "addToCart", retval)();
          if (retval == false) {
            const msg = {
              type: "danger",
              title: this.$t("shopping_basket"),
              text: this.$t("not_add_product"),
            };
            this.$store.dispatch("messages/sendMessage", { message: msg });
          } else {
            if (this.$gtm.enabled()) {
              if (typeof window.fbq === "function") {
                window.fbq("track", "AddToCart");
              }

              if (!this.$gtm.uaDisabled) {
                window.dataLayer.push({
                  event: "eec.add",
                  ecommerce: {
                    add: {
                      products: [
                        {
                          name: this.product.name,
                          id: this.product.sku,
                          quantity: this.quantity,
                        },
                      ],
                    },
                  },
                });
              }
              if (this.$gtm.ga4Enabled) {
                window.dataLayer.push({
                  event: "add_to_cart",
                  ecommerce: {
                    add: {
                      currency: "EUR",
                      value: this.finalPrice,
                      items: [this.getGTMItems],
                    },
                  },
                });
              }
            }
            const msg = {
              type: "success",
              title: "",
              text: this.$t("items_added_to_cart", { numberOf: 1 }),
            };
            this.$store.dispatch("messages/sendMessage", { message: msg });
          }
          break;
        }
        case "ConfigurableProduct": {
          const item =
            '{ parent_sku: "' +
            this.product.sku +
            '" data: {sku: "' +
            this.$store.getters["product/getCurrentChildSku"] +
            '", quantity:' +
            1 +
            // this.quantity +
            "}}  ";
          let retval = await this.$store.dispatch("cart/addToCart", {
            type: this.product.__typename,
            item: item,
          });
          Logger.debug("retval", "addToCart", retval)();
          if (retval == false) {
            const msg = {
              type: "danger",
              title: this.$t("add_to_cart"),
              text: this.$t("not_add_product"),
            };
            this.$store.dispatch("messages/sendMessage", { message: msg });
          } else {
            if (this.$gtm.enabled()) {
              if (typeof window.fbq === "function") {
                window.fbq("track", "AddToCart");
              }

              Logger.debug("Watch Product", "product.vue", "CALLED")();
              window.dataLayer.push({
                event: "eec.add",
                ecommerce: {
                  add: {
                    products: [
                      {
                        name: this.product.name,
                        id: this.product.sku,
                        quantity: 1,
                        // quantity: this.quantity,
                      },
                    ],
                  },
                },
              });
              if (this.$gtm.ga4Enabled) {
                window.dataLayer.push({
                  event: "add_to_cart",
                  ecommerce: {
                    add: {
                      currency: "EUR",
                      value: this.finalPrice,
                      items: [this.getGTMItems],
                    },
                  },
                });
              }
            }

            const msg = {
              type: "success",
              title: "",
              text: this.$t("items_added_to_cart", { numberOf: 1 }),
            };
            this.$store.dispatch("messages/sendMessage", { message: msg });
          }
          break;
        }
        default:
          break;
      }
    },
    async addToCompare(sku) {
      let retval = await this.$store.dispatch("product/addCompareProduct", {
        sku,
      });
      Logger.debug("retval", "addToCompare", retval)();
      if (retval == false) {
        const msg = {
          type: "danger",
          title: "",
          text: "Unable to add",
        };
        this.$store.dispatch("messages/sendMessage", { message: msg });
      } else {
        this.comparePopupProduct = this.compareList.find(
          (prod) => prod.sku === sku
        );
        this.addToComparePopup = true;
      }
    },
    async removeFromCompare(sku) {
      let retval = await this.$store.commit(
        "product/removeProductFromCompare",
        sku
      );
      Logger.debug("retval", "removeFromCompare", retval)();
      if (retval == false) {
        const msg = {
          type: "danger",
          title: "",
          text: "something went wrong",
        };
        this.$store.dispatch("messages/sendMessage", { message: msg });
      }
    },
    formatPrice(price) {
      if (price % 1 === 0) {
        return "€" + price + ",-";
      }
      return "€" + price.toFixed(2).replace(".", ",");
    },
  },
};
</script>

<style lang="scss" scoped></style>
