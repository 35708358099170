<template>
  <div v-if="slider && slider.slides && slider.slides.length > 0">
    <b-link :to="slider.slides[0].link"
      ><img :src="slider.slides[0].media.url"
    /></b-link>
  </div>
</template>

<script>
export default {
  name: "InlineCategoryAdvertisement",
  components: {},
  props: {
    slider: { type: Object, required: true },
  },
  data: () => ({}),
};
</script>

<style lang="scss" scoped></style>
