<template>
  <CatBanner />
</template>

<script>
//import bannerImage from '"../../assets/advertisementBanner01.jpg'
import bannerImage from "@/base/assets/advertisementBanner01.jpg";
import CatBanner from "@/base/core/components/products/category-product/CatBanner";

export default {
  name: "AdvertisingBanner",
  components: { CatBanner },

  data: () => ({
    bannerBg: bannerImage,
  }),
};
</script>

<style lang="scss" scoped></style>
